<div class="relative inline-block text-left  cursor-pointer" #currentScope>
  <div (click)="toggleMenu()" class="dropdown-button">
      <ng-content select=".dropdown-button"></ng-content>
  </div>
  @if(isOpen){
    <div class="absolute left-0 z-10 w-56 rounded-md bg-white shadow-lg ring-black focus:outline-none">
      <div>
        <ng-content select=".dropdown-options"></ng-content>
      </div>
    </div>
  }
</div>
