import {
  Component, ElementRef, HostListener,
  OnInit, ViewChild
} from '@angular/core';
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  standalone: true,
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @ViewChild('currentScope') currentScope?: ElementRef;
  ngOnInit(): void {

  }
  public isOpen = false;

  public toggleMenu(): void {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  public  closeHostClick($event: any){
    if(!this.currentScope?.nativeElement.contains($event.target) && this.isOpen) {
      this.isOpen = false;
    }
  }
}
